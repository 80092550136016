import React from "react";
import Carousel from "@/bit/components/components.carousel";
import Card from "@/home_components/card";
import FeaturedCard from "./featured-card";

const FeaturedDispo = (dispoData) => {
  if (!dispoData) {
    return null;
  }
  const { taggingInfo } = dispoData;
  const featuredTariffCard = dispoData["Information-Card"];
  const featuredDispoCards = Object.keys(dispoData).filter((key) =>
    key.startsWith("Device")
  );

  const renderDispoCards = () => {
    return featuredDispoCards.map((elemento, index) => {
      return (
        <>
          <div
            key={index + "-dispo"}
            className="col-xs-12 col-sm-6 col-md-4 featuredDispoCards-content--dispo_cards "
          >
            <FeaturedCard
              taggingInfo={taggingInfo}
              cardData={dispoData[elemento]}
            />
          </div>
          <style jsx>
            {`
              .featuredDispoCards-content--dispo_cards {
                height: 100%;
              }
            `}
          </style>
        </>
      );
    });
  };

  return (
    <section className="featuredDispoCards">
      {featuredTariffCard && featuredDispoCards && (
        <div className="container featuredDispoCards-content">
          <div className="row">
            {featuredTariffCard && (
              <div className="col-xs-12 col-lg-3 featuredDispoCards-content--tariff">
                <Card taggingInfo={taggingInfo} {...featuredTariffCard} />
              </div>
            )}
            {featuredDispoCards &&
              Array.isArray(featuredDispoCards) &&
              featuredDispoCards.length > 0 && (
                <div className="col-xs-12 col-lg-9 featuredDispoCards-content--dispo">
                  <div className="row display-none-xs">
                    {renderDispoCards()}
                  </div>
                  <div className="display-xs">
                    <Carousel
                      slideSeparation={featuredDispoCards.length}
                      maxPointNum={0}
                      className="FeaturedDispo"
                    >
                      {renderDispoCards()}
                    </Carousel>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
      <style jsx>
        {`
          .featuredDispoCards {
            padding: 0;
            margin: 0 auto;
            line-height: 29px;

            &-content {
              line-height: normal;
              display: grid;
              gap: 1rem;
              margin: 0 auto;
              margin-bottom: 0;
              justify-content: center;
              flex-direction: column;
              align-items: stretch;
              padding-bottom: 0;

              .row {
                grid-row-gap: 0.75rem;
              }
              &--tariff {
                overflow: hidden;
                height: 100%;
                :global(.card.layout-tariff) {
                  gap: 0;
                }
              }
            }
            .display-xs {
              display: block;
              :global(.arrow-container) {
                display: none !important;
              }
            }
            .display-none-xs {
              display: none;
            }
          }

          @media screen and (min-width: 768px) {
            .featuredDispoCards {
              padding: 0;
              margin: 0 auto;
              &-content {
                flex-direction: row;
                .row {
                  grid-row-gap: 1rem;
                }
              }
              .display-xs {
                display: none;
              }
              .display-none-xs {
                display: grid;
              }
            }
          }
          @media screen and (min-width: 1280px) {
            .featuredDispoCards {
              &-content {
                .row {
                  grid-row-gap: 1.5rem;
                }
              }
            }
          }
        `}
      </style>
    </section>
  );
};

export default FeaturedDispo;
