import css from "styled-jsx/css";
import { colors, grid } from "./constants";

export default css`
  .devices-section {
    .content-container {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      border-radius: 1rem;
      border: solid ${colors.color_gp__grey2} 1px;
    }
  }

  // Tablet
  @media screen and (min-width: ${grid.breakpoint_sm}px) {
    .devices-section {
      border: none;

      .content-container {
        padding-bottom: 1rem;
        padding-top: 1rem;
        border: none;
      }
    }
  }
`;
