import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import CloseButton from "@/bit/components/components.close-button";

class VideoModal extends Component {
  constructor(props) {
    super(props);
  }

  drawModal = () => {
    try {
      const modalParent = document.querySelector(".modal-root");
      modalParent.style.zIndex = "35";
      const closeContainer = document.querySelector(".close-container");
      const device = getDeviceKindDependsOnViewPort();
      const modalContent = document.querySelector(".Modal_content");
      if (device === "MOBILE") {
       
        modalContent.style.width = "100%";
        modalContent.style.top = "30%";
        modalContent.style.padding = "0 12px";
        closeContainer.style.top = "-0.25rem";
        closeContainer.style.right = "0.5rem";
      } else {        
        closeContainer.style.top = 0;
        closeContainer.style.right = 0;
      }
    } catch (err) {
      console.error(err);
    }
  };

  closeModal = () => {
    const { addModal } = this.props;
    addModal({ id: "" });
  };

  componentDidMount() {
    this.drawModal();
  }

  componentWillUnmount() {
    const modalParent = document.querySelector(".modal-root");
    modalParent.style.removeProperty("z-index");
    const modalContent = document.querySelector(".Modal_content");
    modalContent.style.removeProperty("width");
    modalContent.style.removeProperty("top");
    modalContent.style.removeProperty("padding");
  }

  render() {
    const { src, srcMp4, alt } = this.props;
    return (
      <>
        <div className="close">
          <CloseButton
            onClick={this.closeModal}
            color="white"
            backgroundColor="transparent"
            taggingInfo={{
              componentName: "video",
              componentContent: "closeVideoModal",
              componentVariation: "all",
              componentPerso: "",
              componentProduct: "",
            }}
          />
        </div>
        <div
          data-cy="video"
          className="video"
          dangerouslySetInnerHTML={{
            __html: `
            <video playsinline autoplay controls controlslist="nodownload"> 
                <source src="${src}" alt="${alt}" type="video/webm"></source>
                <source src="${srcMp4}" alt="${alt}" type="video/mp4"></source>
            </video>
            `,
          }}
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal);
