import React from "react";
import Image from "@/bit/components/components.c-image";
import Text from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";
import PropTypes from "prop-types";
import style from "./style";

const comprobarComponentVariation = (link) => {
  if (link && link.taggingInfo) {
    const { taggingInfo } = link;
    if (taggingInfo && taggingInfo.componentVariation) {
      return taggingInfo.componentVariation;
    }
  }
  return "all";
};

const Slide = ({ img, title, link, taggingInfo }) => {
  const finalTaggingInfo = taggingInfo && {
    ...taggingInfo,
    componentContent: title?.text,
    componentVariation: comprobarComponentVariation(link),
    eventType: "click",
  };

  return (
    <>
      <CustomLink
        {...link}
        taggingInfo={finalTaggingInfo}
        cyData="DeviceSection Carousel CustomLink"
      >
        <div className="device-slide">
          {img && (
            <div className="img-container">
              <Image
                {...img}
                compressionByResize={false}
                cyData="DeviceSection Carousel Image"
              />
            </div>
          )}

          {title && <Text {...title} cyData="DeviceSection Carousel Title" />}

          <div className="hover-effect"></div>
        </div>
      </CustomLink>

      <style jsx>{style}</style>
    </>
  );
};

export default Slide;

Slide.propTypes = {
  src: PropTypes.object,
  text: PropTypes.object,
};
