import React from "react";
import CustomizerComponent from "@/bit/components/components.customizer-component";
import DevicesSection from "../devices-section-free";
import DevicesR2R from "../featured-dispo";

import TextInfo, {
  TEXT_ALIGN,
  TEXT_COLOR,
  TEXT_FONT_WEIGHT,
  TEXT_HEADING_LEVEL,
  TEXT_KIND,
  TEXT_SIZE,
} from "@/bit/components/components.text";

const DeviceSection = ({
  freeDevices,
  freeDevicesActivityName,
  rendToRendDevices,
  rendToRendActivityName,
}) => {
  return (
    <>
      <div className="container devices-section--title">
        <TextInfo
          text="Móviles y dispositivos"
          size={TEXT_SIZE.TITLE1}
          color={TEXT_COLOR.BLUE_DARK}
          kind={TEXT_KIND.HEADING}
          align={TEXT_ALIGN.CENTER}
          fontType={TEXT_FONT_WEIGHT.BOLD}
          level={TEXT_HEADING_LEVEL.H2}
          sizeLegacy
          cyData="DeviceSection Title"
        />
      </div>
      {rendToRendDevices && (
        <CustomizerComponent
          name={rendToRendActivityName}
          Component={DevicesR2R}
          componentProps={rendToRendDevices}
          componentName="devicesSectionR2R"
        />
      )}
      {freeDevices && (
        <CustomizerComponent
          name={freeDevicesActivityName}
          Component={DevicesSection}
          componentProps={freeDevices}
          componentName="devicesSectionFree"
        />
      )}

      <style jsx>{`
        .devices-section--title {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
      `}</style>
    </>
  );
};

export default DeviceSection;
