import css from "styled-jsx/css";
import { grid } from "../constants";

export default css`
  .device-slide {
    width: 11.43rem;
    height: 13.68rem;
    background-color: white;
    border: solid #eeeeee 1px;
    border-radius: 16px;
    position: relative;
    padding: 0.3rem;
    cursor: pointer;

    :global(.nextLinkOverBox) {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      top: 0;
      left: 0;
      transition: all 200ms ease;
    }

    :global(.text) {
      line-height: 1.5rem;
    }

    .img-container {
      width: 100%;
      height: 9.4375rem;

      :global(.device-image) {
        height: 100%;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }

  // Tablet
  @media screen and (min-width: ${grid.breakpoint_sm}px) {
    :global(.carousel-item-wrapper) {
      overflow: visible;
    }

    .device-slide {
      &:hover {
        border: solid rgb(221, 221, 221) 1px;
      }
    }
  }
  // Desktop
  @media screen and (min-width: ${grid.breakpoint_md}px) {
  }
`;
