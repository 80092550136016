import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sendEvent from "@gcloud-npm/utils.metrics.send-ga-component-event";

/*IMPORTS*/

/* DYNAMIC COMPONENTS ENDS   */
/*
 - recibo las props ya pedidas desde pagina
 - recibo el componente ya definido, incluimos lo del scroll
 - permitir modelar el fake para las medias

 - la excepción radica en: (importante pedir esa info cuando CONTEXT SE HAYA ACUTALIZADO POR PRIMERA VEZ Y TENER LLOS HAEHS DE CLIENTE)
  -> qa, queremos que repita en cliente su propia info para tener la foto actualizada (tanto)
  -> al prod vamos a probar la idea de renderizar siempre la no cliente, y rehidratar con la cliente aver que tal va
*/

const ENV_RELOAD_ALWAYS = ["qa", "qa_ssr"];

class CustomizerComponent extends Component {
  static getDerivedStateFromProps = (props, state) => {
    let { reload, finalFetch, name } = state;
    const { context, editMode, componentProps } = props;

    const contextLoaded = !!context.contextSeoMeta;
    const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;
    const logado =
      (context &&
        (context.userIdPartyHash ||
          context.userCUCFijoHash ||
          context.userCUCMovilHash ||
          context.userCUCPrepagoHash)) ||
      "";
    const userPq = props.userPq || {};
    const userPo = props.userPo || {};
    const userRecom = props.userRecom || {};
    let finalName = name;

    // solo me planteo algo si llega el contextLoaded, que tendrá lo que tenga que tener
    if (contextLoaded) {
      const noRequestedInfoYet = reload === 0 && !finalFetch;
      //console.log(`traza: ${name} noRequestedInfoYet:${noRequestedInfoYet}`)
      // vamosa hacer que siempre se pidan las cosas una vez
      if (noRequestedInfoYet && ENV_RELOAD_ALWAYS.includes(currentEnv)) {
        //console.log(`traza: ${name} getDerived reload`)
        reload = 1;
        finalName = props.name;
      }

      if (!ENV_RELOAD_ALWAYS.includes(currentEnv)) {
        // entorno que no recargue siempre, comportamiento habitual, solo pedir cuando estoy logado
        finalName = props.name;

        if (logado) {
          reload = 1;

          //console.log(`traza: ${name} getDerived reload logado`)
        } else {
          //console.log(`traza: ${name} getDerived quedarme con las props actuales marcamos como final Fetch`)
          finalFetch = true;
        }
      }
    }

    return {
      ...state,
      editMode,
      context,
      userPq,
      userPo,
      userRecom,
      // variable uso interno para decirle al compo que debo pedir más info
      reload,
      // cuando ya tenemos toda la info clara y quiero dibujar en vista
      finalFetch,
      name: finalName,
    };
  };

  constructor(props) {
    super(props);
    this.printEventSended = false;
    const { componentProps } = props;
    const allowEdit = `${process.env.NEXT_PUBLIC_ALLOW_EDIT}` === "true";
    this.creativityInitialInfo = null;
    this.state = {
      reload: 0,
      componentProps,
      allowEdit,
      finalFetch: false,
      draftChanges: 0,
      name: null,
    };
  }

  requestActivity = async (context, userPq, userPo, userRecom, name) => {
    const { allowEdit } = this.state;
    const { kind } = this.props;

    try {
      const module = await import("@gcloud-npm/adabyron.custo.get-by-name");
      const getByName = module.default;
      const componentProps = await getByName({
        name,
        context,
        kind,
        userPq,
        userPo,
        userRecom,
        custoVersion: 3,
      });
      // nueva información
      // console.log({componentProps})
      if (allowEdit) {
        this.creativityInitialInfo = componentProps;
      }
      this.setState({
        componentProps,
        finalFetch: true,
      });
    } catch (err) {
      //console.log(err)
    }
  };

  componentDidMount = () => {
    const { reload, finalFetch, context, userPq, userPo, userRecom, name } =
      this.state;
    //console.log(`traza: ${name} componentDidMount`, { context});

    if (!finalFetch && reload === 1 && name) {
      //console.log(`traza: ${name} tengo que pedir desde Didmount`)
      this.requestActivity(context, userPq, userPo, userRecom, name);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(`traza: ${name} shouldcomponent`, { nextProps, nextState}, this.state)
    const { finalFetch, editMode } = this.state;
    const {
      finalFetch: nextFinalFetch,
      reload: nextReload,
      userPq: nextUserPq,
      userPo: nextuserPo,
      userRecom: nextUserRecom,
      context: nextContext,
      editMode: nextEditMode,
      name: nextName,
    } = nextState;
    //console.log(`traza: ${name} => shouldrequest: ${shouldrequest}`, {nextFinalFetch, nextReload})

    if (!nextFinalFetch && nextReload === 1 && nextName) {
      //console.log(`traza: ${name} tengo que pedir desde should`)
      this.requestActivity(
        nextContext,
        nextUserPq,
        nextuserPo,
        nextUserRecom,
        nextName
      );
    }
    const editModePlays = editMode || nextEditMode;
    const changeEditmode = editMode !== nextEditMode;
    //console.log(`traza: ${name} shouldComponentUpdate => ${result}`);
    const changeName = this.props.name !== nextName;

    return (
      finalFetch !== nextFinalFetch ||
      editModePlays ||
      changeEditmode ||
      changeName
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { componentName, name } = this.props;
    const { componentProps, allowEdit } = this.state;
    const emptyContent =
      componentProps &&
      componentProps.creativityName &&
      componentProps.creativityName === "CMS-EMPTY-CONTENT";
    const hasTuringRecom =
      componentProps &&
      componentProps.taggingInfo &&
      componentProps.taggingInfo.componentPerso &&
      componentProps.taggingInfo.componentPerso !== "none";
    const { finalFetch } = this.state;
    if (
      hasTuringRecom &&
      !emptyContent &&
      finalFetch &&
      !this.printEventSended &&
      componentProps &&
      componentProps.taggingInfo
    ) {
      this.printEventSended = true;
      const { taggingInfo } = componentProps;
      sendEvent({
        ...taggingInfo,
        componentPerso: JSON.stringify(taggingInfo.componentPerso),
        eventType: "print-success",
        componentName,
      });
    }
  }
  /*METHODS*/

  render() {
    const { Component, skeletonClassName, componentName } = this.props;
    const { finalFetch, allowEdit, editMode } = this.state;
    let { componentProps } = this.state;

    if (!componentProps) {
      return null;
    }

    const { taggingInfo, creativityId } = componentProps;
    const finalTaggingInfo = {
      ...taggingInfo,
      componentName,
    };

    const isVisibleCreativity =
      componentProps &&
      (!componentProps.display || componentProps.display !== "none");
    if (!isVisibleCreativity && finalFetch) {
      return null;
    }

    if (
      isVisibleCreativity &&
      componentProps &&
      Object.keys(componentProps).length > 0
    ) {
      let custoObjectInfo = null;
      if (allowEdit && editMode) {
        custoObjectInfo = this.createCustoDescObject();
        const draft = getFromSession(creativityId);
        if (draft) {
          componentProps = draft;
        }
      }

      return (
        <Component
          {...componentProps}
          taggingInfo={finalTaggingInfo}
          custoObjectInfo={custoObjectInfo}
          skeletonClassName={
            !finalFetch ? `skeleton ${skeletonClassName}` : "skeleton loaded"
          }
          finalFetch={finalFetch}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  context: state.main.context,
  userPq: state.main.userPq,
  userPo: state.main.userPo,
  userRecom: state.main.userRecom,
  editMode: state.main.editMode,
});

// const mapDispatchToProps = (dispatch) => ({
//   addModal: (data) => dispatch(modalActions.addModal(data)),
// });

CustomizerComponent.propTypes = {
  skeletonClassName: PropTypes.string,
  kind: PropTypes.string,
};

CustomizerComponent.defaultProps = {
  skeletonClassName: "",
  componentProps: {},
  kind: "activity",
};

export default connect(mapStateToProps, null)(CustomizerComponent);
