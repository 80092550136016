import css from "styled-jsx/css";
import { grid } from "../../constants";

export default css`
  .benefit-item {
    padding-bottom: 0.75rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    :global(.svg-layout-size) {
      min-width: 18px;
    }
  }

  // Tablet
  @media screen and (min-width: ${grid.breakpoint_sm}px) {
  }
  // Desktop
  @media screen and (min-width: ${grid.breakpoint_md}px) {
  }
`;
