import React, { useRef, useState, useEffect } from "react";
 
let loadedCss = false;
const loadCss = (href) => {
  try {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
  } catch(err){
    // console.error(err)
  }
}


const useOnScreen = (ref, options) => {
  const [isVisible, setVisible] = useState((options && options.isVisible) || false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if(options && options.loadCss && !loadedCss) {
          loadCss(options.loadCss);
          loadedCss = true;
        }
        if (!isVisible) {
          setVisible(true);
        }
      }
    }, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);
  return isVisible;
};

const ClientLoad = ({ children, options }) => {
  const sectionRef = useRef();
  const sectionIsOnScreen = useOnScreen(sectionRef, options);
  return <div ref={sectionRef}>{sectionIsOnScreen && children}</div>;
};

export default ClientLoad;
