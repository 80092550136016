import React from "react";
import Glyph from "@/bit/components/components.icon-glyph";
import Text from "@/bit/components/components.text";
import style from "./style";

const BenefitItem = ({ icon, text }) => {
  let glyph;
  switch (icon.id) {
    case "check":
    default:
      glyph = <Glyph {...icon} />;
  }

  return (
    <>
      <li className="benefit-item" data-cy="Benefit List Item">
        {glyph}
        <Text {...text} cyData="Benefit List Item Text" />
      </li>
      <style jsx>{style}</style>
    </>
  );
};

export default BenefitItem;
