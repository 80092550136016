import React from "react";
import CustomizerComponent from "@/bit/components/components.customizer-component";
import Card from "@/home_components/card";

const Banner = ({ bannerProps, activityNames }) => {
  return (
    <section className="container banner-cards" data-cy="Section heros">
      <div className="banner-cards--content">
        <div className="banner-cards--content_card banner-cards--content_card--one">
          <CustomizerComponent
            name={activityNames[0]}
            Component={Card}
            componentProps={{ ...bannerProps[0], setPriority: true }}
            componentName="BannerCardHero1"
            skeletonClassName="skeleton-banner-card"
          />
        </div>
        <div className="banner-cards--content_card banner-cards--content_card--two">
          <CustomizerComponent
            name={activityNames[1]}
            Component={Card}
            componentProps={{ ...bannerProps[1], setPriority: true }}
            componentName="BannerCardHero2"
            skeletonClassName="skeleton-banner-card"
          />
        </div>
        <div className="banner-cards--content_card banner-cards--content_card--three">
          <CustomizerComponent
            name={activityNames[2]}
            Component={Card}
            componentProps={{ ...bannerProps[2], setPriority: true }}
            componentName="BannerCardHero3"
            skeletonClassName="skeleton-banner-card"
          />
        </div>
      </div>
      <style jsx>
        {`
          .banner-cards {
            padding-top: 0.75rem;

            :global(.price.price-layout--default) {
              display: inline-block;
              margin-right: 0.5rem;
            }
            :global(.price.price-layout--compact) {
              display: inline-flex;
              margin-right: 0.5rem;
            }

            :global(.price--post-price) {
              margin-top: 0.5rem;
            }
            :global(.card.layout-product .card-container--label) {
              min-height: auto;
            }
            :global(.card.layout-product .card-container) {
              flex-direction: column;
              justify-content: flex-start;
            }
            :global(.card.layout-product .card-content--price) {
              display: none;
            }

            :global(.card.layout-product .card-header--subtitle) {
              display: none;
            }
            :global(.card.layout-dispo-product .card-container) {
              flex-direction: column;
            }
            :global(.card.layout-dispo-product .card-container--label) {
              :global(.label) {
                margin-bottom: 0.25rem;
              }
            }
            :global(.card.layout-dispo-product .card-content--price) {
              display: none;
            }
            :global(.card.layout-dispo-product) {
              padding: 5%;
            }
            :global(.card) {
              padding: 0;
              :global(.card-container) {
                gap: 0;
              }
              :global(.card-container--image .custom-image) {
                border-radius: 0;
              }

              :global(.card-container--info) {
                padding: 5%;
                :global(.card-container--label) {
                }
              }

              :global(.card-container--image) {
                max-width: none;
                aspect-ratio: unset;
                padding-top: 75%;
              }
            }

            :global(.card.layout-dispo-product .card-container--info) {
              padding: 0;
            }
            &--content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin: 0 auto;
              justify-content: space-between;

              &_card {
                padding: 0;
                position: relative;
                display: flex;
                width: 100%;
              }

              &_card:not(:first-child) {
                max-width: 49%;
              }

              &--two,
              &--three {
                display: none !important;
              }
            }

            /* Tablet */
            @media screen and (min-width: 768px) {
              margin: 0 auto;
              padding-top: 1rem;
              padding-bottom: 1rem;
              :global(.card.layout-product .card-header--pretitle) {
                display: block;
              }
              :global(.card.layout-product .card-header--subtitle) {
                display: block;
              }
              :global(.card.layout-product .card-content--price) {
                display: block;
              }
              :global(.card.layout-dispo-product .card-content--price) {
                display: block;
              }
              &--content {
                flex-wrap: nowrap;
                gap: 1rem;
              }
            }

            @media screen and (min-width: 1280px) {
              margin: 0 auto;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              &--content {
                flex-wrap: nowrap;
                gap: 1.5rem;
              }
            }
          }

          @media screen and (max-width: 768px) {
            .banner-cards--content_card {
              &--two,
              &--three {
                :global(.text-size_legacy--t1),
                :global(.text-size_legacy--t2),
                :global(.text-size_legacy--t3),
                :global(.text-size_legacy--t4),
                :global(.text-size--t1),
                :global(.text-size--t2),
                :global(.text-size--t3),
                :global(.text-size_legacy--title03) {
                  font-size: clamp(20px, 4.4vw, 22px);
                  line-height: 110% !important;
                }
              }
            }
          }
        `}
      </style>
    </section>
  );
};

export default Banner;
