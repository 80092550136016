import React from "react";
import Glyph from "@/bit/components/components.icon-glyph";
import CustomLink from "@/bit/components/components.custom-link";
import Text, { TEXT_SIZE, TEXT_COLOR } from "@/bit/components/components.text";
import style from "./style";

const StoreLink = ({ infolink }) => {
  let glyph;

  switch (infolink["Icon"].id) {
    case "cheuron-right":
    default:
      glyph = <Glyph {...infolink["Icon"]} id="cheuron-right" />;
  }  

  return (
    <>
      <CustomLink
       {...infolink}
        taggingInfo={{     
          ...infolink.taggingInfo,                   
          componentName: "devicesSectionFree",
          componentContent: infolink["title"],
          eventType: "click",
        }}
        cyData="DeviceSection StoreLink"
      >
        <Text text={infolink["title"]} size={TEXT_SIZE.TITLE6} color={TEXT_COLOR.BLUE} cyData="DeviceSection StoreLink Text" />
        {glyph}
      </CustomLink>
      <style jsx>{style}</style>
    </>
  );
};

export default StoreLink;
