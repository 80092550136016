import React from "react";
import Text from "@/bit/components/components.text";
import BenefitItem from "./benefit-item";
import StoreLink from "./store-link";
import style from "./style";

const BenefitsInfo = ({ benefits, link, title }) => {
  return (
    <div className="col-xs-12 col-sm-6 col-md-3 benefits-container" data-cy="DeviceSection Benefits">
      <div className="benefit-info">
        {title && <Text {...title} cyData="DeviceSection Benefit Title" />}

        <ul className="benefits-list" data-cy="DeviceSection Benefit List">
          {benefits &&
            benefits.map((e, i) => (
              <BenefitItem icon={e["Glyph-1"]} text={e["Text-1"]} key={`benefit-${i}`} />
            ))}
        </ul>

        {link && <StoreLink infolink={link} />}
      </div>
      <style jsx>{style}</style>
    </div>
  );
};

export default BenefitsInfo;
