import React from "react";
import TextInfo from "@/bit/components/components.text";

const Title = (props) => {
  if (props && props["Text-1"]) {
    return (
      <div className="container featuredOfferCards--title">
        <TextInfo {...props["Text-1"]}  sizeLegacy/>
      </div>
    );
  }
  return null;
};

export default Title;
