import React from "react";
import PropTypes from "prop-types";
import DeviceCarousel from "./device-carousel";
import BenefitsInfo from "./benefits-info";
import style from "./style";

const DevicesSection = (props) => {
  const benefits = Object.keys(props["Information-Card"])
    .filter((key) => key.startsWith("Item-"))
    .map((key) => props["Information-Card"][key]);

  const carouselContent = Object.keys(props["Carrusel"])
    .filter((key) => key.startsWith("Slide-"))
    .map((key) => props["Carrusel"][key]);

  const taggingInfo = props.taggingInfo;

  return (
    <>
      <section className="devices-section container" data-cy="DeviceSection Container">
        <div className="content-container">
          <div className="row">
            <BenefitsInfo
              benefits={benefits}
              link={props["Information-Card"]["CustomLink-1"]}
              title={props["Information-Card"]["Text-0"]}
            />
            <DeviceCarousel
              content={carouselContent}
              taggingInfo={taggingInfo}
            />
          </div>
        </div>
      </section>
      <style jsx>{style}</style>
    </>
  );
};

export default DevicesSection;

DevicesSection.propTypes = {};

DevicesSection.defaultProps = {};
