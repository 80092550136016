import React from "react";
import PropTypes from "prop-types";
import { getColor } from "@gcloud-npm/utils.enums.colors";
import TextInfo from "@/bit/components/components.text";
import CardTemplate from "@/home_components/card/card-template";
import PostCard from "@/home_components/card/post-card";
import CustomLink from "@/bit/components/components.custom-link";
import Image from "@/bit/components/components.c-image";

const extraerTextoSpan = (cadena) => {
  const patron = /<span[^>]*>(.*?)<\/span>/;
  const elementoEncapsulado = patron.test(cadena);
  if (elementoEncapsulado) {
    const resultado = cadena.match(patron);
    if (resultado) {
      return resultado[1];
    } else {
      null;
    }
  }
  return cadena;
};

const makeUpComoponentPerso = (taggingInfo) => {
  if (taggingInfo && taggingInfo.componentPerso) {
    return JSON.stringify(taggingInfo.componentPerso);
  }
  return "";
};

const comprobarComponentVariation = (taggingInfo, link) => {
  if (taggingInfo && taggingInfo.componentVariation) {
    return taggingInfo.componentVariation;
  } else if (link && link.componentVariation) {
    return link.componentVariation;
  }
  return "all";
};

const computeBackgroundColor = (color) => {
  if (color && typeof color === "string") {
    if (color.charAt(0) === "#") {
      return color;
    } else {
      return getColor(color);
    }
  }
  return getColor(color);
};

const Card = (restProps) => {
  const {
    layout,
    backgroundColor,
    skeletonClassName,
    className,
    cardShadow,
    backgroundOpacity,
    custoObjectInfo,
    children,
    setPriority,
    imageAspectRatio,
    finalFetch,
  } = restProps;

  const titleCusto = restProps["Text-2"]?.text || restProps["Text-1"]?.text;
  const link = restProps["CustomLink-1"]?.text;
  const backgroundImg = restProps["Image-1"];
  const video = restProps["Video-1"];
  let finalClassName = className;
  // const imgBackground = {
  //   desktop: restProps["Image-1"],
  //   tablet: restProps["Image-tablet"],
  //   mobile: restProps["Image-mobile"],
  // };

  /* EDITING MODE BEGINS */
  let DetailBar = null;
  let editableClassName = "card-column";
  let ociImage1 = null;
  let ociCustomLink1 = null;
  let ociText0 = null;

  if (custoObjectInfo) {
    editableClassName = `${editableClassName} customizer-component`;
    // si ele primer nodo marcarlo
    if (custoObjectInfo.entryPoint === "") {
      editableClassName = `${editableClassName} custo`;
      DetailBar = custoObjectInfo.components.DetailBar;
    }
    // marcamos nodos a editar
    ociText0 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-0",
    });
    ociImage1 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Image-1",
    });
    ociCustomLink1 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "CustomLink-1",
    });
  }
  /* EDITING MODE ENDS */

  var taggingInfo;
  try {
    taggingInfo = restProps.taggingInfo && {
      ...restProps.taggingInfo,
      componentVariation: comprobarComponentVariation(
        restProps.taggingInfo,
        link
      ),
      componentContent: extraerTextoSpan(titleCusto),
      componentPerso: makeUpComoponentPerso(restProps.taggingInfo),
      eventType: "click",
    };
  } catch (err) {}

  const computeBackgroundColor = (color) => {
    // console.log("color: ", color);
    if (color && typeof color === "string") {
      if (color.charAt(0) === "#") {
        return color;
      } else {
        return getColor(color);
      }
    }
    return getColor(color);
  };

  const cardStyle = {
    backgroundColor: computeBackgroundColor(backgroundColor),
  };

  if (cardShadow && cardShadow != "false" && cardShadow != false) {
    finalClassName =
      finalClassName + ` card-shadow--${layout === "dispo-product" ? 1 : 10}`;
  }

  return (
    <>
      <div className={editableClassName}>
        {DetailBar}
        {restProps["Text-0"] && restProps["Text-0"].text && (
          <div className="card-title">
            <TextInfo
              {...restProps["Text-0"]}
              sizeLegacy
              cyData="Card TV Text-0"
              custoObjectInfo={ociText0}
            />
          </div>
        )}

        <div
          className={`card layout-${layout} ${finalClassName}`}
          style={cardStyle}
        >
          <>
            <CustomLink
              {...restProps["CustomLink-1"]}
              title={restProps["CustomLink-1"]?.title || ""}
              taggingInfo={taggingInfo}
              className="card-clickable"
              custoObjectInfo={ociCustomLink1}
              cyData="Card CustomLink-1"
            >
              {video && video.src && (
                <div
                  className="video"
                  dangerouslySetInnerHTML={{
                    __html: `
            <video muted autoplay loop playsinline> 
                <source src="${video.src}" alt="${video.alt}" type="video/webm"></source>
                <source src="${video.srcMp4}" alt="${video.alt}" type="video/mp4"></source>
            </video>
            `,
                  }}
                ></div>
              )}
              {backgroundImg && backgroundImg.src && (
                <div className={`card-backgroundImg-1`}>
                  <Image
                    {...backgroundImg}
                    priority={setPriority}
                    fill
                    objectFit={backgroundImg.objectFit || "cover"}
                    custoObjectInfo={ociImage1}
                    cyData={`Card Image-1 ${backgroundImg.alt}`}
                    display={finalFetch}
                  />
                  {!!backgroundOpacity && (
                    <div className="card-img--opacity"></div>
                  )}
                </div>
              )}
              {!video && (
                <div className="card-container">
                  {children && children}
                  {!children && (
                    <CardTemplate
                      {...restProps}
                      custoObjectInfo={custoObjectInfo}
                      finalFetch={finalFetch}
                      cardClassName={
                        restProps["Text-7"] && restProps["Text-7"].text
                          ? "post-card"
                          : "no-post-card"
                      }
                    />
                  )}
                </div>
              )}
            </CustomLink>
          </>
          <div className={skeletonClassName} />
        </div>

        {/* Post Card Content Mobile */}
        {restProps["Text-7"] && (
          <PostCard
            {...restProps}
            custoObjectInfo={custoObjectInfo}
            skeletonClassName={skeletonClassName}
          />
        )}
      </div>
      <style jsx>
        {`
          .card-title {
            margin-bottom: 1rem;
          }
        `}
      </style>
    </>
  );
};

Card.propTypes = {
  layout: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

Card.defaultProps = {
  layout: "capta",
  backgroundColor: "transparent",
  className: "",
};

export default Card;
