import React, { useRef, useEffect } from "react";

function MainVideo({
  src,
  srcMp4,
  alt,
  playVideo = true,
  noClipPath,
  expand,
  width,
  height,
}) {
  return (
    <>
      <div
        data-cy="Superbanner Video"
        className="superbanner-video"
        dangerouslySetInnerHTML={{
          __html: `
      <video muted loop playsinline autoplay>
          <source src="${src}" alt="${alt}" type="video/webm"></source>
          <source src="${srcMp4}" alt="${alt}" type="video/mp4"></source>
      </video>
      `,
        }}
      ></div>
      <style jsx>{`
        .superbanner-video {
          position: absolute;
          top: 0;
          left: 0;
          width: ${width || `80%`};
          height: ${height || `auto`};

          ${expand && `aspect-ratio: 1.2 / 1;`}

          overflow: visible;
          ${expand && `transform: scale(1) translateX(-15%) translateY(-13%);`}

          ${expand && `transform-origin: right;`}
          ${!noClipPath && `clip-path: circle(43%)`}

          :global(video),
          :global(.image) {
            object-fit: contain;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        @media screen and (max-width: 768px) {
          .superbanner-video {
            ${expand && `transform-origin: top left;`}
            ${expand &&
            `transform: scale(0.9) translateX(-13%) translateY(-6%);`}
            
            ${!noClipPath && `clip-path: circle(43.5%)`}
          }
        }
      `}</style>
    </>
  );
}

export default MainVideo;
