export const colors = {
  color_emp__blue_dark: "#0B2739",
  color_gp__grey2: "#EEEEEE",
  color_gp__grey5: "#86888C",
  color_gp__grey4: "#999999",
  color_gp__grey6: "#313235",
  color_gp__blue: "#019DF4",
};

export const grid = {
  max_width_xs: "328",
  width_xs: "100%",
  grid_gap_xs: "8",

  breakpoint_sm: "768",
  breakpoint_md: "1224",
  breakpoint_lg: "1280",
  breakpoint_xl: "1512",
};
