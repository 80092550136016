import React, { useMemo } from "react";
import dynamic from "next/dynamic";

import getHeaderSections from '@gcloud-npm/normalizers.components-header.sections'
import getBreadcrumbs from '@gcloud-npm/normalizers.components-header.breadcrumbs'
import getSeo from '@gcloud-npm/adabyron.custo.get-seo'
import getPage from '@gcloud-npm/adabyron.custo.get-page'


import DisplayOnViewportIntersection from "@/bit/components/components.display-on-viewport-intersection";
import CustomizerComponent from "@/bit/components/components.customizer-component";
import MetaData from "@/bit/core/core.meta-data";
import Header from "@/bit/components/components.header";
import Footer from "@/bit/components/components.footer";
import { wrapper } from "store";
import { END } from "redux-saga";
import Banner from "@/home_components/banner";
import getByName from "@gcloud-npm/adabyron.custo.get-by-name";
import FeaturedOffer from "@/home_components/featured-offer";
import DeviceSection from "@/home_components/devices-section";
import Teletipo from "@/home_components/teletipo";
import { useSelector } from "react-redux";
import SuperBanner from "@/home_components/superbanner";

const AdvantagesSection = dynamic(() =>
  import("@/home_components/advantages-section")
);

const StreamingPlatformsSection = dynamic(() =>
  import("@/home_components/streaming-platforms-section")
);

const Ecosystem = dynamic(() => import("@/home_components/ecosystem"));

const Home = (props) => {
  const {
    breadcrumbsProps,
    headerProps,
    seoInfo,
    // creatividades
    teletipo,
    banner1,
    banner2,
    banner3,
    offerTitle,
    offer1,
    offer2,
    offer3,
    offer4,
    sliderContenidos,
    rendToRendDevices,
    freeDevices,
    advantages,
    ecosystem,
    loggedActivityNames,
    noLoggedActivityNames,
    superBanner,
    footerProps,
  } = props;

  const {
    userIdPartyHash,
    userCUCFijoHash,
    userCUCMovilHash,
    userCUCPrepagoHash,
  } = useSelector((state) => state.main.context);

  const activities = useMemo(() => {
    const isLogged =
      userIdPartyHash ||
      userCUCFijoHash ||
      userCUCMovilHash ||
      userCUCPrepagoHash ||
      "";

    return isLogged ? loggedActivityNames : noLoggedActivityNames;
  }, [userIdPartyHash, userCUCFijoHash, userCUCMovilHash, userCUCPrepagoHash]);

  return (
    <>
      <MetaData seoInfo={seoInfo} />
      <Header {...breadcrumbsProps} {...headerProps} />

      <main>
        <CustomizerComponent
          name={activities.teletipo}
          Component={Teletipo}
          componentName="Teletipo"
          componentProps={teletipo}
          skeletonClassName="teletipo-skeleton"
        />

        {superBanner && (
          <CustomizerComponent
            name={activities.superBanner}
            Component={SuperBanner}
            componentProps={superBanner}
            componentName="SuperBanner"
            skeletonClassName="skeleton-superbanner"
          />
        )}

        {banner1 && banner2 && banner3 && (
          <Banner
            bannerProps={[banner1, banner2, banner3]}
            activityNames={[
              activities.banner1,
              activities.banner2,
              activities.banner3,
            ]}
          />
        )}
        <FeaturedOffer
          offerProps={[offer1, offer2, offer3, offer4]}
          offerActivities={[
            activities.offer1,
            activities.offer2,
            activities.offer3,
            activities.offer4,
          ]}
          title={offerTitle}
          titleActivity={activities.offerTitle}
        />
        <DisplayOnViewportIntersection options={{ rootMargin: "500px" }}>
          <DeviceSection
            rendToRendDevices={rendToRendDevices}
            rendToRendActivityName={activities.rendToRendDevices}
            freeDevices={freeDevices}
            freeDevicesActivityName={activities.freeDevices}
          />
        </DisplayOnViewportIntersection>

        <DisplayOnViewportIntersection options={{ rootMargin: "500px" }}>
          <CustomizerComponent
            name={activities.sliderContenidos}
            Component={StreamingPlatformsSection}
            componentName="StreamingPlatformSection"
            componentProps={sliderContenidos}
          />
        <DisplayOnViewportIntersection options={{ rootMargin: "500px" }}>
          <CustomizerComponent
            name={activities.ecosystem}
            Component={Ecosystem}
            componentProps={ecosystem}
            componentName="Ecosystem"
            skeletonClassName="skeleton-ecosystem"
          />
        </DisplayOnViewportIntersection>
        </DisplayOnViewportIntersection>
        <CustomizerComponent
          name={activities.advantages}
          Component={AdvantagesSection}
          componentName="StreamingPlatformSection"
          componentProps={advantages}
        />
      </main>
      <Footer footerProps={footerProps} />
    </>
  );
};

export default Home;

export const getStaticProps = wrapper.getStaticProps(async ({ store }) => {
  const isNotProd = `${process.env.NEXT_PUBLIC_ENV_NAME}` !== "prod";
  const context = store.getState().main.context;

  const [noLoggedPage, loggedPage] = await Promise.all([
    getPage({
      name: "CHAMELEON_HOME_NOLOGADO",
      build: true,
      context,
    }),
    getPage({
      name: "CHAMELEON_HOME_LOGADO",
      build: true,
      context,
    }),
  ]);

  const creaIndex = {
    teletipo: 0,
    banner1: 1,
    banner2: 2,
    banner3: 3,
    offerTitle: 4,
    offer1: 5,
    offer2: 6,
    offer3: 7,
    offer4: 8,
    ecosystem: 9,
    rendToRendDevices: 10,
    freeDevices: 11,
    sliderContenidos: 12,
    advantages: 13,
    superBanner: 14,
  };

  // funciona como un map, pero para objetos
  const objectMap = (obj, fn) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    );

  const noLoggedKeys = Object.keys(noLoggedPage);
  const loggedKeys = Object.keys(loggedPage);

  // actividades de la página no logado
  const pageActivities = objectMap(
    creaIndex,
    (value) => noLoggedPage[noLoggedKeys[value]] || null
  );

  // nombre de las actividades logado
  const loggedActivityNames = objectMap(
    creaIndex,
    (value) => loggedKeys[value] || null
  );

  // nombre de las actividades no logado
  const noLoggedActivityNames = objectMap(
    creaIndex,
    (value) => noLoggedKeys[value] || null
  );

  const seoInfo = await getSeo({
    context,
    location: "www.movistar.es/particulares",
  });

  // HEADER USUAL PROPS
  const breadcrumbsInfoJson = await getByName({
    name: "HEADER-INFO",
    kind: "activity",
    context,
    build: true,
  });
  const url = "";
  const breadcrumbsProps = getBreadcrumbs(breadcrumbsInfoJson, url);
  const headerInfoJson = await getByName({
    name: "HEADER-BURGUER-MENU-1",
    kind: "creativity",
    context,
    build: true,
  });

  // HEADER REDUX PROPS
  const headerProps = getHeaderSections(headerInfoJson, breadcrumbsProps);

  // FOOTER USUAL PROPS
  const footerProps = await getByName({
    name: "FOOTER-INFO",
    kind: "activity",
    context,
    build: true,
  });
  store.dispatch(END);
  await store.sagaTask.toPromise();

  const allProps2Return = {
    props: {
      seoInfo,
      headerProps: {...headerProps, location: '/'},
      breadcrumbsProps,
      loggedActivityNames,
      noLoggedActivityNames,
      noLoggedPage: noLoggedPage,
      footerProps,
      ...pageActivities,
    },
    revalidate: 600
  }

  if (isNotProd && allProps2Return.revalidate) {
    delete allProps2Return.revalidate;
  }

  return allProps2Return;
});
