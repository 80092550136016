import React from "react";
import TextInfo, { TEXT_DISPLAY } from "@/bit/components/components.text";
import Glyph from "@/bit/components/components.icon-glyph";
import Image from "@/bit/components/components.c-image";
import Price from "@/bit/components/components.price";
import Label from "@/bit/components/components.label";
import TextList from "@/home_components/text-list";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const CardTemplate = (cardProps) => {
  if (!cardProps) {
    return null;
  }
  const { custoObjectInfo } = cardProps;
  const headerIcon = cardProps["Glyph-1"];
  const backgroundGlyph = cardProps["Glyph-2"];
  let img = null;
  const device = getDeviceKindDependsOnViewPort();
  if (device === "MOBILE" && cardProps["Image-4"] && cardProps["Image-4"].src) {
    img = cardProps["Image-4"];
  } else {
    img = cardProps["Image-2"];
  }

  const imgThree = cardProps["Image-3"];
  const labelZero = cardProps["Label-0"];
  const label = cardProps["Label-1"];
  let labelTwo = cardProps["Label-2"];
  const headerPretitle = cardProps["Text-1"];
  const headerTitle = cardProps["Text-2"];
  const headerSuperTitle = cardProps["Text-6"];
  const headerPosTitle = cardProps["Text-3"];
  const price = cardProps["Price-2"];
  const priceTachado = cardProps["Price-1"];
  const prevPrice = cardProps["Text-4"];
  const postPrice = cardProps["Text-5"];
  const textList = cardProps["Characteristics-1"];  
  const postCardText = cardProps["Text-7"];
  const postCardPrice = cardProps["Price-3"];
  const smallPrice = cardProps["Price-4"];
  const layout = cardProps.layout;
  const imageClassName = cardProps?.imageClassName || "";
  const cardClassName = cardProps?.cardClassName || "";
  const finalFetch = cardProps?.finalFetch;

  const showPrice =
    (price && price.amount === 0 && true) ||
    (price && price.amount) ||
    (prevPrice && prevPrice.text) ||
    (postPrice && postPrice.text);

  if (labelTwo && labelTwo.theme == "ahorro" && labelTwo.label != "") {
    const { originalPrice, monthlyPayment } = cardProps;
    const r2rPrice = price?.amount;
    if (r2rPrice && originalPrice && monthlyPayment) {
      const ahorro =
        parseFloat(monthlyPayment) * parseFloat(r2rPrice) -
        parseFloat(originalPrice) +
        1;
      if (ahorro < -10) {
        const normalizedSavingForLabel = Number.isInteger(ahorro)
          ? -ahorro
          : -ahorro?.toFixed(2)?.replace(".", ",");
        labelTwo = {
          label: labelTwo.label + ` ${normalizedSavingForLabel}€`,
          theme: "discount",
          inversed: false,
          notBorderRadius: true,
        };
      }
    }
  }

  /* editable functions */
  let objectCustoInfoGlyph1 = null;
  let objectCustoInfoGlyph2 = null;
  let ociImage2 = null;
  let ociImage3 = null;
  let ociLabel0 = null;
  let ociLabel1 = null;
  let ociLabel2 = null;
  let ociText1 = null;
  let ociText2 = null;
  let ociText6 = null;
  let ociText3 = null;
  let ociPrice2 = null;
  let ociPrice4 = null;
  let ociPrice1 = null;
  let ociText4 = null;
  let ociText5 = null;
  let ociText7 = null;
  let ociPrice3 = null;

  if (custoObjectInfo) {
    // objectCustoInfoGlyph1 = custoObjectInfo.addEntryPoint({ custoObjectInfo, entryPoint: 'Glyph-1' })
    // objectCustoInfoGlyph2 = custoObjectInfo.addEntryPoint({ custoObjectInfo, entryPoint: 'Glyph-2' })
    ociImage2 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Image-2",
    });
    ociImage3 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Image-3",
    });
    ociLabel0 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Label-0",
    });
    ociLabel1 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Label-1",
    });
    ociLabel2 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Label-2",
    });
    ociText1 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-1",
    });
    ociText2 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-2",
    });
    ociText6 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-6",
    });
    ociText3 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-3",
    });
    ociPrice2 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Price-2",
    });
    ociPrice4 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Price-4",
    });
    ociPrice1 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Price-1",
    });
    ociText4 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-4",
    });
    ociText5 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-5",
    });
    ociText7 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-7",
    });
    ociPrice3 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Price-3",
    });
  }

  let colorPrice = price?.color || "grey6";
  if (layout === "dispo-product") {
    colorPrice = priceTachado?.amount != 0 ? "red_dark" : "grey6";
  }

  return (
    <>
      {img && img.src && (
        <div className={`card-container--image ${imageClassName}`}>
          <Image
            {...img}
            fill
            priority={cardProps?.setPriority || false}
            objectFit={img?.objectFit ? img.objectFit : "contain"}
            cyData={`Card Image-2 ${img.alt}`}
            display={finalFetch}
          />
        </div>
      )}
      <div className={`card-container--info ${cardClassName}`}>
        {labelZero && (
          <div className="card-container--label card-container--label--zero">
            {labelZero.label && (
              <Label
                {...labelZero}
                layout="fantasy"
                cyData="Card Label-1"
                custoObjectInfo={ociLabel0}
              />
            )}
          </div>
        )}
        {(headerIcon ||
          label ||
          headerPretitle ||
          headerSuperTitle ||
          headerPosTitle ||
          showPrice) && (
          <div className="card-header">
            {headerIcon && headerIcon.id && (
              <>
                <div className="card-header--icon" data-cy="Card Glyph-1">
                  <Glyph
                    {...headerIcon}
                    custoObjectInfo={objectCustoInfoGlyph1}
                  />
                </div>
                {layout === "tariff" && (
                  <div className="card-header--icon_support"></div>
                )}
              </>
            )}
            {label && (
              <div className="card-container--label">
                {label.label && (
                  <Label
                    {...label}
                    custoObjectInfo={ociLabel1}
                    cyData="Card Label-1"
                  />
                )}
              </div>
            )}
            {headerPretitle &&
              headerPretitle.text && (
                <TextInfo
                  className="card-header--pretitle"
                  {...headerPretitle}
                  sizeLegacy
                  custoObjectInfo={ociText1}
                  cyData="Card Text-1"
                />
              )}

            {headerTitle && headerTitle.text && (
              <TextInfo
                className="card-header--title"
                {...headerTitle}
                sizeLegacy
                custoObjectInfo={ociText2}
                cyData="Card Text-2"
              />
            )}

            {headerSuperTitle && headerSuperTitle.text && (
              <TextInfo
                className="card-header--supertitle"
                {...headerSuperTitle}
                sizeLegacy
                custoObjectInfo={ociText6}
                cyData="Card Text-6"
              />
            )}

            {headerPosTitle && headerPosTitle.text && (
              <TextInfo
                className="card-header--subtitle"
                {...headerPosTitle}
                sizeLegacy
                custoObjectInfo={ociText3}
                cyData="Card Text-3"
              />
            )}
          </div>
        )}

        {showPrice && (
          <div className="card-content">
            {labelTwo && labelTwo.label && (
              <div className="card-container--label card-container--label-two">
                <Label
                  {...labelTwo}
                  cyData="Card Label-2"
                  custoObjectInfo={ociLabel2}
                />
              </div>
            )}

            <div className="card-content--price">
              {prevPrice && prevPrice.text && (
                <TextInfo
                  className="price--pre-price"
                  {...prevPrice}
                  sizeLegacy
                  custoObjectInfo={ociText4}
                  size="caption"
                  cyData="Card Text-4"
                />
              )}
              <div className="card-content--price-block">
                {priceTachado &&
                  priceTachado.amount &&
                  priceTachado.amount != 0 && (
                    <Price
                      price={priceTachado.amount}
                      currency={priceTachado.unity}
                      quote={priceTachado.frequency}
                      cyData="Card Price-1"
                      custoObjectInfo={ociPrice1}
                      size="caption"
                      {...priceTachado}
                    />
                  )}
                {layout &&
                  layout === "tariff" &&
                  price &&
                  (price.amount || price.amount == 0) && (
                    <div className="card-content--price_small">
                      {smallPrice && smallPrice.amount ? (
                        <Price
                          price={smallPrice.amount}
                          currency={smallPrice.unity}
                          quote={smallPrice.frequency}
                          cyData="Card Price-4"
                          custoObjectInfo={ociPrice4}
                          {...smallPrice}
                          color={colorPrice}
                        />
                      ) : (
                        <Price
                          price={price.amount}
                          currency={price.unity}
                          quote={price.frequency}
                          cyData="Card Price-2"
                          custoObjectInfo={ociPrice2}
                          {...price}
                          color={colorPrice}
                        />
                      )}
                    </div>
                  )}
                {price && (price.amount || price.amount == 0) && (
                  <div className="card-content--price_big">
                    <Price
                      price={price.amount}
                      currency={price.unity}
                      quote={price.frequency}
                      cyData="Card Price-2"
                      custoObjectInfo={ociPrice2}
                      {...price}
                      color={colorPrice}
                    />
                  </div>
                )}
              </div>
              {postPrice && postPrice.text && (
                <TextInfo
                  className="price--post-price"
                  {...postPrice}
                  sizeLegacy
                  custoObjectInfo={ociText5}
                  cyData="Card Text-5"
                />
              )}
            </div>
          </div>
        )}
        {postCardText && postCardText.text && (
          <div className="post-card-mobile">
            <TextInfo
              className="card-header--title"
              {...postCardText}
              custoObjectInfo={ociText7}
              cyData="Card Text-7"
            />
            {postCardPrice &&
              (postCardPrice.amount || postCardPrice.amount == 0) && (
                <Price
                  price={postCardPrice.amount}
                  currency={postCardPrice.unity}
                  quote={postCardPrice.frequency}
                  cyData="Card Price-3"
                  custoObjectInfo={ociPrice3}
                  {...postCardPrice}
                />
              )}
          </div>
        )}
      </div>
      {textList && Object.entries(textList).length > 0 && (
        <div className="card-text-list">
          <TextList items={textList} />
        </div>
      )}

      {imgThree && imgThree.src && (
        <div className="card-container--image-three">
          <Image
            {...imgThree}
            priority={cardProps?.setPriority || false}
            objectFit="contain"
            fill
            objectPosition="center"
            custoObjectInfo={ociImage3}
            cyData={`Card Image-3 ${imgThree.alt}`}
          />
        </div>
      )}
      {backgroundGlyph && backgroundGlyph.src && (
        <div className="card-glyph">
          <object
            type="image/svg+xml"
            data={backgroundGlyph.src}
            title={
              backgroundGlyph.alt ? backgroundGlyph.alt : "Ofertas Movistar"
            }
          ></object>
        </div>
      )}
    </>
  );
};

export default CardTemplate;
