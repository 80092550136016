import css from "styled-jsx/css";
import { grid } from "../../constants";

export default css`
  :global(.store-link) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.375rem;

    & > :global(.svg-layout-size) {
      transition: transform 200ms ease-in-out;
    }

    &:hover {
      & > :global(.svg-layout-size) {
        transform: translateX(0.5rem);
      }
    }
  }

  // Desktop
  @media screen and (min-width: ${grid.breakpoint_md}px) {
  }
`;
