import css from "styled-jsx/css";
import { grid } from "../constants";

export default css`
  .benefit-info {
    padding: 1rem;

    .benefits-list {
      padding-left: 1rem;
      padding-bottom: 1.25rem;
    }

    :global(.title) {
      padding-bottom: 1.5rem;
    }
  }

  // Tablet
  @media screen and (min-width: ${grid.breakpoint_sm}px) {
    .benefit-info {
      padding: 0;
    }
  }
  // Desktop
  @media screen and (min-width: ${grid.breakpoint_md}px) {
  }
`;
