import React, { useEffect, useRef, useState } from "react";
import Text from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";
import Price from "@/bit/components/components.price";
import Button from "@/bit/components/components.button";
import CustomLink from "@/bit/components/components.custom-link";
import Label from "@/bit/components/components.label";
import Video from "./video-component";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const makeUpComoponentPerso = (taggingInfo) => {
  if (taggingInfo && taggingInfo.componentPerso) {
    return JSON.stringify(taggingInfo.componentPerso);
  }
  return "";
};

function videoSelection(props) {
  const videoMobile = props["Video-3"];
  const videoTablet = props["Video-2"];
  const videoDesktop = props["Video-1"];

  let video = null;
  const device = getDeviceKindDependsOnViewPort();
  if (device === "MOBILE") {
    video = videoMobile;
  } else if (device === "TABLET") {
    video = videoTablet;
  } else {
    video = videoDesktop;
  }
  return video;
}
function SuperBanner(props) {
  const imageMobile = props["Image-3"];
  const imageTablet = props["Image-2"];
  const imageDesktop = props["Image-1"];
  const [video, setVideo] = useState(null);
  useEffect(() => {
    const video = videoSelection(props);
    setVideo(video);
  });

  let taggingInfo;
  try {
    taggingInfo = props.taggingInfo && {
      ...props.taggingInfo,
      componentPerso: makeUpComoponentPerso(props.taggingInfo),
      eventType: "click",
      componentContent: "contratar",
    };
  } catch (err) {}

  const hasBackgroundVideo = props["Video-2"] && props["Video-2"].src;

  const layoutCompact = props?.layout === "compact";
  const layoutMini = props?.layout == "mini";
  const hastImageMobileTwo =
    props["ImageMobile-2"] && props["ImageMobile-2"]?.src;

  let link = null;
  if (layoutMini) {
    link = props["CustomLink-1"];
  }

  return (
    <>
      {layoutMini && (
        <div className="container">
          <div className="minibanner">
            {imageMobile && imageMobile.src && !video && (
              <div className="image-mobile">
                <Image fill cyData="minibanner image-mobile" {...imageMobile} />
              </div>
            )}
            {imageTablet && imageTablet.src && !video && (
              <div className="image-tablet">
                <Image
                  fill
                  className="image-tablet"
                  cyData="minibanner image-tablet"
                  {...imageTablet}
                />
              </div>
            )}
            {imageDesktop && imageDesktop.src && !video && (
              <div className="image-desktop">
                <Image
                  fill
                  className="image-desktop"
                  cyData="minibanner image-desktop"
                  {...imageDesktop}
                />
              </div>
            )}
            {video && video?.src && (
              <div className="video-background">
                <Video {...video} expand={false} width="100%" height="100%" />
              </div>
            )}

            {link && (link.href || link.onClick) && <CustomLink {...link} />}
            <div className={`${props?.skeletonClassName}`}></div>
          </div>
        </div>
      )}

      {!layoutMini && (
        <div className="container">
          <div className="superbanner row">
            {props["CustomLink-1"] && props["CustomLink-1"].href && (
              <div className="superbanner-link-cont">
                <CustomLink
                  {...props["CustomLink-1"]}
                  taggingInfo={taggingInfo}
                ></CustomLink>
              </div>
            )}
            <div
              className={`col-md-5 col-sm-5 col-xs-12 ${
                hasBackgroundVideo ? "video-cont" : "img-cont"
              }`}
            >
              {!hastImageMobileTwo &&
                props["Image-2"] &&
                props["Image-2"]?.src && (
                  <div className="img-background">
                    <Image {...props["Image-2"]} width={2000} height={2000} />
                    {props["Image-2"] &&
                      props["Image-2"]?.["linear-gradient"] && (
                        <div className="img-background-linear-gradient" />
                      )}
                  </div>
                )}

              {hastImageMobileTwo && (
                <>
                  <div className="display--desktop">
                    {props["Image-2"] && props["Image-2"]?.src && (
                      <div className="img-background">
                        <Image
                          {...props["Image-2"]}
                          width={2000}
                          height={2000}
                        />
                        {props["Image-2"] &&
                          props["Image-2"]?.["linear-gradient"] && (
                            <div className="img-background-linear-gradient" />
                          )}
                      </div>
                    )}
                  </div>

                  <div className="display--mobile">
                    {props["ImageMobile-2"] && props["ImageMobile-2"]?.src && (
                      <div className="img-background">
                        <Image
                          {...props["ImageMobile-2"]}
                          width={2000}
                          height={2000}
                        />
                        {props["ImageMobile-2"] &&
                          props["ImageMobile-2"]?.["linear-gradient"] && (
                            <div className="img-background-linear-gradient" />
                          )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {props["Video-2"] && props["Video-2"]?.src && (
                <div className="video-background">
                  <Video
                    {...props["Video-2"]}
                    expand={false}
                    width="100%"
                    height="100%"
                  />
                  {props["Video-2"] &&
                    props["Video-2"]?.["linear-gradient"] && (
                      <div className="img-background-linear-gradient" />
                    )}
                </div>
              )}

              {props["Image-1"] && props["Image-1"].src && (
                <div className="main-image">
                  <Image
                    {...props["Image-1"]}
                    width={1285}
                    height={1000}
                    priority={true}
                  />
                </div>
              )}

              {props["Video-1"] && props["Video-1"]?.src && (
                <Video {...props["Video-1"]} expand={true} />
              )}
            </div>

            {layoutCompact || <div className="superbanner-separator" />}

            <div className="content-container col-md-6 col-sm-6 col-xs-12">
              {props["LabelMobile-1"] && props["LabelMobile-1"].label && (
                <div className="display--mobile">
                  <Label {...props["LabelMobile-1"]} />
                </div>
              )}

              {props["Text-1"] && props["Text-1"].text && (
                <Text {...props["Text-1"]} />
              )}

              {props["Text-2"] && props["Text-2"].text && (
                <div className="superbanner-title">
                  <Text {...props["Text-2"]} />
                </div>
              )}

              {props["Text-3"] && props["Text-3"].text && (
                <div className="display--desktop">
                  <Text {...props["Text-3"]} />
                </div>
              )}

              {props["TextMobile-3"] && props["TextMobile-3"].text && (
                <div className="display--mobile">
                  <Text {...props["TextMobile-3"]} />
                </div>
              )}

              <div className="display--desktop">
                {props["Label-1"] && props["Label-1"]?.label && (
                  <Label {...props["Label-1"]} />
                )}

                <div className="superbanner-price-cont">
                  {props["Price-1"] && props["Price-1"].price && (
                    <Price {...props["Price-1"]} />
                  )}

                  {props["Price-2"] && props["Price-2"].price && (
                    <Price {...props["Price-2"]} />
                  )}
                </div>
              </div>

              {props["Text-4"] && props["Text-4"].text && (
                <div className="display--desktop">
                  <Text {...props["Text-4"]} />
                </div>
              )}

              <div className="display--desktop">
                {props["Button-1"] && props["Button-1"].href && (
                  <div className="superbanner-button-cont">
                    <Button {...props["Button-1"]} taggingInfo={taggingInfo} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="display--mobile">
            <div className="superbanner-price-cont-mobile">
              {props["PriceMobile-1"] && props["PriceMobile-1"]?.price && (
                <Price {...props["PriceMobile-1"]} />
              )}

              {props["PriceMobile-2"] && props["PriceMobile-2"]?.price && (
                <Price {...props["PriceMobile-2"]} />
              )}
            </div>
          </div>

          <div className={`${props?.skeletonClassName}`}>
            <div className="row skeleton-superbanner-cont">
              <div className="col-md-4 superbanner-post-card"></div>
              <div className="col-md-4 superbanner-post-card"></div>
              <div className="col-md-4 superbanner-post-card"></div>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .minibanner {
          position: relative;
          margin-bottom: 1rem;
          margin-top: 1rem;
          padding-top: 43%;
          .image-mobile {
            display: block;
          }
          .image-tablet {
            display: none;
          }
          .image-desktop {
            display: none;
          }
          :global(.superbanner-video){            
            overflow:hidden;
          }
          .video-background {
            :global(video) {
              object-fit: cover;
              width:100%;
              height:100%;
              position:absolute;
              top:0%;
              max-width:100%;
              left: -0%;
            }
          }
          @media screen and (min-width: 768px) {
            padding-top: 24.5%;
            .image-mobile {
              display: none;
            }
            .image-tablet {
              display: block;
            }
            .image-desktop {
              display: none;
            }
          }

          @media screen and (min-width: 1024px) {
            padding-top: 13.35%;
            .image-mobile {
              display: none;
            }
            .image-tablet {
              display: none;
            }
            .image-desktop {
              display: block;
            }
          }

          :global(img) {
            border-radius: 16px;
          }
        }
        .superbanner {
          background-color: ${props?.["background-color"] || "#f5f5f5"};
          border-radius: 16px;
          margin-top: 1rem;
          overflow: visible;
          height: fit-content;

          .superbanner-link-cont {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .video-background {
            :global(video) {
              width: 100%;
              height: 100%;
            }
          }

          .img-cont,
          .video-cont {
            width: 100%;
            height: 100%;
            overflow: visible;
            position: relative;
            padding-top: 80%;
            pointer-events: none;

            .main-image {
              transform: scale(1.2);
              transform-origin: bottom;
              width: 80%;
              height: 100%;
            }

            .main-video {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            .img-background {
              border-radius: 0rem 0rem 1rem 1rem;
              overflow: hidden;

              .img-background-linear-gradient {
                position: absolute;
                width: 7rem;
                height: 100%;
                top: 0;
                right: -1px;
                display: none;

                background: linear-gradient(
                  90deg,
                  rgb(88, 42, 42, 0),
                  ${props?.["background-color"] || "rgba(245, 245, 245, 1)"},
                  ${props?.["background-color"] || "rgba(245, 245, 245, 1)"}
                );
              }
            }

            .img-background {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;

              :global(img),
              :global(video),
              > :global(div) {
                width: 100%;
                height: 100%;
              }
            }

            :global(img) {
              object-position: bottom;
              object-fit: cover;
              position: absolute;
              bottom: 0;
              left: 0;
              position: absolute;
              bottom: 0;

              width: 100%;
            }
          }

          .video-cont {
            padding-top: 60%;
            grid-row: 1;
          }

          .superbanner-separator {
            display: none;
          }

          .content-container {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            gap: 0.5rem;
            padding: 1rem;
            order: -1;
            margin-bottom: 1rem;

            .superbanner-title {
            }

            .superbanner-price-cont {
              margin-top: 0.75rem;
              display: flex;
              gap: 1rem;
            }
          }

          .superbanner-button-cont {
            max-width: 280px;
          }

          .display {
            &--desktop {
              display: none;
            }

            &--mobile {
              display: block;
            }
          }
        }

        .superbanner-price-cont-mobile {
          display: flex;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          flex-flow: row wrap;
        }

        @media screen and (min-width: 768px) {
          .superbanner {
            margin-top: ${layoutCompact ? "2rem" : "1rem"};
            margin-bottom: ${layoutCompact ? "2rem" : "1rem"};

            .img-cont {
              aspect-ratio: unset;
              padding-top: ${layoutCompact ? 70 : 80}%;

              .img-background {
                border-radius: 1rem 0rem 0rem 1rem;
                .img-background-linear-gradient {
                  display: block;
                }
              }
            }

            .content-container {
              order: 1;
              margin-bottom: 0rem;
            }

            .display {
              &--desktop {
                display: block;
              }

              &--mobile {
                display: none;
              }
            }
          }

          .superbanner-price-cont-mobile {
            display: none;
          }
        }

        @media screen and (min-width: 1024px) {
          .superbanner {
            margin-top: ${layoutCompact ? "2rem" : " 4rem"};
            margin-bottom: ${layoutCompact ? "1rem" : "2rem"};

            .img-cont {
            }

            .superbanner-separator {
              display: block;
            }

            .content-container {
              order: 1;
              margin-bottom: 0rem;

              padding-top: ${layoutCompact ? "2rem" : "3rem"};
              padding-bottom: ${layoutCompact ? "2rem" : "3rem"};
            }
          }
        }
      `}</style>
    </>
  );
}

export default SuperBanner;
