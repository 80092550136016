import css from "styled-jsx/css";
import { grid } from "../constants";

export default css`
  .device-carousel {
    :global(.carousel) {
      :global(.carousel-window) {
        padding-right: 0.9375rem;
      }
    }
  }

  // Tablet
  @media screen and (min-width: ${grid.breakpoint_sm}px) {
    .device-carousel {
      :global(.carousel) {
        padding: 0;
      }
    }
  }

  // Desktop
  @media screen and (min-width: ${grid.breakpoint_md}px) {
  }
`;
