/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import { connect } from "react-redux";
import CustomLink from "@/bit/components/components.custom-link";
import Text from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import getColor from "@gcloud-npm/utils.enums.colors";
import Glyph from "@/bit/components/components.icon-glyph";
import VideoModal from "./video-modal";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
const makeUpComoponentPerso = (taggingInfo) => {
  if (taggingInfo && taggingInfo.componentPerso) {
    return JSON.stringify(taggingInfo.componentPerso);
  }
  return "";
};

class Teletipo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
    };
  }

  handleVideoModal = () => {
    const { addModal } = this.props;

    addModal({
      id: "video",
      Element2Show: VideoModal,
      modalProps: {
        closeIdByClickingOutside: "closeAll",
        contentPosition: "center--center",
        inSceneEffect: "fadeIn",
        contentInsideContainer: false,
        outSceneEffect: "fadeOut",
        closeByClickingOutside: true,
        scrollAllowed: false,
        createAnchor: false,
      },
      elementProps: {
        ...this.props["Video-1"],
      },
    });
  };

  render() {
    const props = this.props;
    const { skeletonClassName, custoObjectInfo } = props;

    const background = getColor(props["backgroundColor"]) || getColor("GREY1");

    var taggingInfo;
    try {
      taggingInfo = props.taggingInfo && {
        ...props.taggingInfo,
        componentName: "Teletipo",
        componentPerso: makeUpComoponentPerso(props.taggingInfo),
        eventType: "click",
      };
    } catch (err) {}

    let className = "section-teletipo";

    /* EDITING MODE BEGINS */
    let DetailBar = null;
    if (custoObjectInfo) {
      className = `${className} customizer-component`;
      // si ele primer nodo marcarlo
      if (custoObjectInfo.entryPoint === "") {
        className = `${className} custo`;
        DetailBar = custoObjectInfo.components.DetailBar;
      }
    }
    /* EDITING MODE ENDS */

    return (
      <section className={className} data-cy="Section teletipo">
        {DetailBar}
        <div className="teletipo">
          {props["Glyph-1"] && (
            <Glyph
              id={props["Glyph-1"].id.toLowerCase()}
              kind={props["Glyph-1"].kind}
              width={props["Glyph-1"].width}
              color={props["Glyph-1"].color}
            />
          )}
          <div className="text-gap">
            {props["Text-1"] && (
              <div className="lineinfo-gap">
                <Text {...props["Text-1"]} cyData="Teletipo Text-1" />
              </div>
            )}
          </div>
          {props["CustomLink-1"] && (
            <CustomLink
              taggingInfo={taggingInfo}
              {...props["CustomLink-1"]}
              cyData="Teletipo CustomLink-1"
            />
          )}
          {props["Button-1"] && (
            <div className="blue-button">
              <Button {...props["Button-1"]} cyData="Teletipo Button-1" />
            </div>
          )}
          {props["Video-1"] && (
            <CustomLink
              onClick={this.handleVideoModal}
              title="video"
              ariaLabel="video"
              taggingInfo={taggingInfo}
              cyData="Header burger"
            />
          )}
        </div>

        <div className={skeletonClassName} />
        <style jsx>
          {`
            @font-face {
              font-family: "TelefonicaSansRegular";
              src: url("https://prod.cms.col.movistar.es/static/fonts/Telefonica_Sans_Regular.woff2");
            }
            @font-face {
              font-family: "TelefonicaSansMedium";
              src: url("https://prod.cms.col.movistar.es/static/fonts/Telefonica_Sans_Medium.woff2");
            }
            .section-teletipo {
              position: relative;
              background-color: ${background};
              .teletipo {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                height: 56px;
                :global(.teletipo-button) {
                  background-color: transparent !important;
                  border-color: transparent !important;
                }
                :global(.svg-layout-size) {
                  overflow: visible;
                }
                @media screen and (max-width: 768px) {
                  padding: 0.75rem;
                  gap: 0.3rem;
                  .text-gap {
                    width: 85%;
                  }
                }
              }
            }
          `}
        </style>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teletipo);
