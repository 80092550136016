import React from "react";
import Carousel from "@/bit/components/components.carousel";
import Slide from "../slide";
import style from "./style";

const DeviceCarousel = ({ content, taggingInfo }) => {
  return (
    <>
      <div
        className="col-xs-12 col-sm-6 col-md-9 device-carousel"
        data-cy="DeviceSection Carousel"
      >
        <Carousel slideSeparation={8} className="DeviceSection">
          {content?.map((e) => (
            <Slide
              img={e["Image-1"]}
              title={e["Text-1"]}
              link={e["CustomLink-1"]}
              key={`${e["Image-1"]}-${e["Text-1"]}`}
              taggingInfo={taggingInfo}
            />
          ))}
        </Carousel>
      </div>
      <style jsx>{style}</style>
    </>
  );
};

export default DeviceCarousel;
